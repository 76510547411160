import { jwtDecode } from "jwt-decode";
import { createContext, useState } from "react";

export const vendorLoginContext = createContext(null);

export default function VendorLoginProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);

  const decodeToken = (token) => {
    try {
      const decodedToken = jwtDecode(String(token));

      return decodedToken;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const compareUserId = (storedUserId) => {
    if (token) {
      const decoded = decodeToken(token);
      if (decoded && decoded.user_id) {
        localStorage.setItem("Vendor User", decoded.user_id);
        return storedUserId === decoded.user_id;
      }
    }
    return false;
  };

  return (
    <vendorLoginContext.Provider
      value={{ token, setToken, decodeToken, compareUserId,userData, setUserData }}
    >
      {children}
    </vendorLoginContext.Provider>
  );
}
