import axios from "axios";
import { createContext, useState } from "react";

// Create the Salesforce context
export const SalesforceContext = createContext(null);

const TOKEN_KEY = "Token";
const OKTA_DOMAIN_KEY = "OKTA_DOMAIN";
const CompanyName = "CompanyName";

// Function to get the stored token from localStorage
const getStoredToken = () => {
  const storedCredentials = localStorage.getItem(TOKEN_KEY);
  return storedCredentials ? JSON.parse(storedCredentials) : null;
};

export default function SalesforceProvider({ children }) {
  const [tokens, setTokens] = useState(getStoredToken);

  // Fetch and update the credentials
  const fetchCredentials = async () => {
    const domain = localStorage.getItem(OKTA_DOMAIN_KEY);
    const companyName = localStorage.getItem(CompanyName);

    if (!companyName && !domain) {
      return null;
    }

    try {
      let newToken = null;
      if (companyName) {
        const { data } = await axios.post(
          `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/companies/email`,
          { companyName }
        );
        newToken = data.data; // Assuming the token is stored in `data.data`
      } else if (domain) {
        const { data } = await axios.get(
          `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/companies/${domain}`
        );
        newToken = data.data; // Assuming the token is stored in `data.data`
      }

      if (newToken) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(newToken));
        setTokens(newToken); // Ensure `setTokens` is properly updating the state or context
        return newToken;
      } else {
        console.log("No new token received.");
      }
    } catch (refreshError) {
      console.error(
        "Error refreshing token:",
        refreshError.message || refreshError
      );
    }
    return null;
  };

  return (
    <SalesforceContext.Provider value={{ tokens, fetchCredentials, setTokens }}>
      {children}
    </SalesforceContext.Provider>
  );
}
