import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const PoliciesContext = createContext([]);

export default function PoliciesProvider({ children }) {
  const { tokens, fetchCredentials } = useContext(SalesforceContext);

  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    if (tokens) {
      getPolicies();
    }
  }, [tokens]);

  const getPolicies = async () => {
    try {
      const { data } = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/polices",
        {}, // Empty body if you don't need to send data
        {
          headers: { Authorization: `Bearer ${tokens}` }, // Authorization header
        }
      );
      setPolicies(data);
    } catch (error) {
      if (
        error.response.data.message === "Token expired" &&
        error.response.status === 401
      ) {
        await fetchCredentials();
      } else console.error("Error fetching data:", error);
    }
  };

  return (
    <PoliciesContext.Provider value={{ policies, getPolicies }}>
      {children}
    </PoliciesContext.Provider>
  );
}
