import React, { createContext, useState, useContext, useEffect } from "react";
import OktaAuth from "@okta/okta-auth-js";
import { Decryption, Encryption } from "../lib/Decryption";

const OktaConfigContext = createContext();

export const useOktaConfig = () => useContext(OktaConfigContext);

export const OktaConfigProvider = ({ children }) => {
  const [oktaConfig, setOktaConfig] = useState(() => {
    // Initialize with localStorage to prevent setting state within the effect.
    const storedCredentials = localStorage.getItem("_Id");
    const decryptData = Decryption(storedCredentials);
    return storedCredentials ? decryptData : {};
  });

  const [oktaAuths, setOktaAuths] = useState(null);

  useEffect(() => {
    if (oktaConfig.clientId && oktaConfig.issuer) {
      const authClient = new OktaAuth(oktaConfig);
      // Store credentials in localStorage only if they change meaningfully.
      if (
        !localStorage.getItem("_Id") ||
        localStorage.getItem("_Id") !== JSON.stringify(oktaConfig)
      ) {
        const encryptData = Encryption(JSON.stringify(oktaConfig));
        localStorage.setItem("_Id", encryptData);
      }
      setOktaAuths(authClient);
    }
  }, [oktaConfig]);

  // Return a context value even if oktaAuths is null to prevent undefined errors
  const value = { oktaAuths, setOktaConfig };

  return (
    <OktaConfigContext.Provider value={value}>
      {children}
    </OktaConfigContext.Provider>
  );
};
