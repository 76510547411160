// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyAVPC8xGh4kqlcH79uyNJquCcs000uNqpQ",
    authDomain: "policy-portal-4ca94.firebaseapp.com",
    projectId: "policy-portal-4ca94",
    storageBucket: "policy-portal-4ca94.appspot.com",
    messagingSenderId: "921694714952",
    appId: "1:921694714952:web:267a1a8793fb9b918fac7d",
    measurementId: "G-HK5BN3FT6Y"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
