import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import css from '../Vendors/style.module.css'
export default function SkeletonQuestionsPage() {
    return (
        <div className="ps-5">
            <div className="mb-3">
                <p className="my-4 fs-6">
                    <Skeleton width={200} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </p>
                <label className="custom-checkbox d-block ms-2">
                    <Skeleton circle={true} height={20} width={20} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                    <Skeleton width={100} className="ms-2" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </label>
                <label className="custom-checkbox d-block ms-2">
                    <Skeleton circle={true} height={20} width={20} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                    <Skeleton width={100} className="ms-2" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </label>
            </div>

            <div>
                <p className="mt-4 fs-6">
                    <Skeleton width={200} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </p>
                <div className="d-flex mt-2 ms-2">
                    <Skeleton height={40} width={120} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                    <Skeleton width={200} className="ms-2" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </div>
                <div className="ms-3 mt-2">
                    <Skeleton width={20} height={20} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                    <Skeleton width={100} className="ms-2" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </div>
            </div>

            <div>
                <p className="mt-4 fs-6">
                    <Skeleton width={200} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
                </p>
                <Skeleton width="100%" height={90} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
            </div>

            <div className="text-danger fw-bold">
                <Skeleton width={200} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
            </div>

            <Skeleton width={120} height={40} className="me-3 mt-3" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
            <Skeleton width={140} height={40} className="mt-3" baseColor="#EBE6F0" highlightColor="#f5f5f5" />
            <Skeleton width={200} height={30} className="mt-3" baseColor="#EBE6F0" highlightColor="#f5f5f5" />


            <Skeleton width={200} height={20} baseColor="#EBE6F0" highlightColor="#f5f5f5" />
        </div>
    );
}

