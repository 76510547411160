import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function SkeletonVendorPage() {
  return (
    <table className="table-bordered documents rounded-table ms-1 p-0" style={{ marginTop: "30px" }}>
    <thead>
      <tr>
        <th scope="col" className="py-1 px-2">#</th>
        <th scope="col" className="py-1 px-2">Name</th>
        <th scope="col" className="py-1 px-2">Type</th>
        <th scope="col" className="py-1 px-2">Date Assigned</th>
        <th scope="col" className="py-1 px-2">Due Date</th>
      </tr>
    </thead>
    <tbody>
      <tr >
        <th scope="row" className="py-1 px-2 border">
          <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} />
        </th>
        <td className="py-1 px-2">
          <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} />
        </td>
        <td className="py-1 px-2">
          <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} />
        </td>
        <td className="py-1 px-2">
          <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} />

        </td>
        <td className="py-1 px-2">
          <Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} />
        </td>
      </tr>
    </tbody>
  </table>
  );
}

