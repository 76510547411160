import React, { useContext, useEffect, useRef, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { VendorContext } from '../../Context/vendorContext';
import { useLocation } from 'react-router-dom';
import Questions from './Questions';
import css from './style.module.css';
import axios from 'axios';
import { SalesforceContext } from "../../Context/SF.CredantialsContext";


export default function Questionnaires() {
    const { tokens, fetchCredentials } = useContext(SalesforceContext);

    const location = useLocation();
    const { id, pirm_addon__Status__c, Audits_r } = location.state;

    const [questionnaire, setQuestionnaire] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [status, setStatus] = useState({});
    const [position, setPosition] = useState(0);
    const isStatusInitialized = useRef(false); // To track if status is already initialized
  

    const getVendorQuestionnaire = async (id, token) => {
        try {
            if (tokens) {
                // First attempt to fetch the data with the current token
                const response = await axios.post(
                    `https://api-skhwrpqgyq-uc.a.run.app/questionnaire/${id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const finalResult = response.data.data;
                finalResult.sort((a, b) => {
                    const aParentTemplate =
                        a.pirm_addon__Question__r?.pirm_addon__Template__r
                            ?.pirm_addon__Parent_Template__c;
                    const bParentTemplate =
                        b.pirm_addon__Question__r?.pirm_addon__Template__r
                            ?.pirm_addon__Parent_Template__c;

                    if (!aParentTemplate && bParentTemplate) return -1;
                    if (aParentTemplate && !bParentTemplate) return 1;
                    return 0;
                });

                return finalResult;
            }
        } catch (error) {
            // Check if the error is token expiration-related
            if (
                error.response?.status === 401 &&
                error.response?.data?.message === "Token expired"
            ) {
                // Attempt to refresh the token
                const refreshSuccess = await fetchCredentials();
                if (refreshSuccess) {
                    return await getVendorQuestionnaire(id, refreshSuccess);
                }

            } else {
                console.error("Error fetching questionnaire:", error.response?.data?.message || error.message);
            }
        }
    };

    useEffect(() => {
        const fetchQuestionnaire = async () => {
            if (!id) return;
            try {
                const data = await getVendorQuestionnaire(id, tokens);
                setAllQuestions(data); // Update allQuestions first
            } catch (error) {
                console.error('Error fetching vendor questionnaire:', error);
            }
        };
        fetchQuestionnaire();
    }, [id]);

    useEffect(() => {
        if (!allQuestions || allQuestions.length === 0) return; // Check if allQuestions is defined and non-empty

        const groupedData = allQuestions.reduce((acc, item) => {
            const key = item.pirm_addon__Question__r.pirm_addon__Template__r.Name;
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);

            return acc;
        }, {});

        setQuestionnaire(Object.values(groupedData));

        if (!isStatusInitialized.current) {
            const initialStatus = Object.keys(groupedData).reduce((acc, _, index) => {
                acc[index] = index === 0;
                return acc;
            }, {});
            setStatus(initialStatus);
            isStatusInitialized.current = true;
        }
    }, [allQuestions]);

    const updateAnswer = (Question, selectedAnswer) => {
        // Create a new array where only the item with the matching Id is updated
        const updatedStatus = allQuestions?.map((item) => {
            if (item.Id === Question.Id) {
                // Update the 'pirm_addon__Answer__c' for the specific question
                return { ...item, pirm_addon__Answer__c: selectedAnswer };
            }
            return item; // Ensure all items are returned as is
        });

        // Replace the state with the updated array
        setAllQuestions(updatedStatus);


        return updatedStatus; // Optional, if you need to return the updated data
    };

    const TestClickUpdate = (Question, selectedAnswer, isMultiSelect = false) => {

        const updatedClickStatus = allQuestions?.map((item) => {
            if (item.Id === Question.Id) {
                if (isMultiSelect) {
                    // Multi-select logic: Toggle selected answer in array
                    let updatedAnswers = Array.isArray(item.pirm_addon__Answer__c)
                        ? item.pirm_addon__Answer__c.includes(selectedAnswer)
                            ? item.pirm_addon__Answer__c.filter((ans) => ans !== selectedAnswer) // Remove
                            : [...item.pirm_addon__Answer__c, selectedAnswer] // Add
                        : [selectedAnswer]; // Initialize if not an array


                    item.pirm_addon__Answer__c = updatedAnswers.length > 0 ? updatedAnswers : null;
                } else {
                    // Single-select logic: Ensure only one answer is selected
                    item.pirm_addon__Answer__c = [selectedAnswer]; // Always store as an array, but only one answer
                }
            }

            // Handle related question logic as before (if applicable)
            if (item.pirm_addon__Question__r.pirm_addon__Related_Question__c === Question.pirm_addon__Question__c) {
                if (
                    item.pirm_addon__IsApplicable__c === true &&
                    !JSON.parse(item.pirm_addon__Question__r.pirm_addon__Related_Answers__c).includes(selectedAnswer)
                ) {
                    return { ...item, pirm_addon__IsApplicable__c: false };
                }
                if (JSON.parse(item.pirm_addon__Question__r.pirm_addon__Related_Answers__c).includes(selectedAnswer)) {
                    return { ...item, pirm_addon__IsApplicable__c: true };
                }
            }

            return item;
        });

        setAllQuestions(updatedClickStatus); // Update the state with new answers
    };


    const handleClick = (index) => {
        setStatus(() => {
            const updatedStatus = {};
            Object.keys(status).forEach((key) => {
                updatedStatus[key] = parseInt(key) === index;
            });
            setPosition(index);
            return updatedStatus;
        });
    };


    const DeleteFileParent = async (question, docId, tokens) => {
        if (!tokens || !docId || !question) return;

        try {
            // Make the DELETE request to the backend
            const response = await axios.delete(
                `https://api-skhwrpqgyq-uc.a.run.app/delete/file/${docId}`,
                {
                    headers: {
                        Authorization: `Bearer ${tokens}`,
                    },
                }
            );

            if (response.status === 200) {
                // Update the questions after successful deletion
                setAllQuestions((prevAllQuestions) => {
                    return prevAllQuestions.map((item) => {
                        // Check if the current item matches the question
                        if (item.Id === question.Id && item.ContentDocumentLinks && item.ContentDocumentLinks.records) {
                            // Remove the file from the records array
                            item.ContentDocumentLinks.records = item.ContentDocumentLinks.records.filter(
                                (file) => file.ContentDocument.Id !== docId
                            );
                            // Decrease the totalSize by 1
                            item.ContentDocumentLinks.totalSize -= 1;
                            // If no files left, set ContentDocumentLinks to null
                            if (item.ContentDocumentLinks.totalSize === 0) {
                                item.ContentDocumentLinks = null;
                            }
                        }
                        return item;
                    });
                });
            }
        } catch (error) {
         

            if (error.response && error.response.status === 401) {
               

                // Refresh the token
                const newToken = await fetchCredentials();


                // If token refresh is successful, retry the delete operation with the new token
                if (newToken) {
                    await DeleteFileParent(question, docId, newToken);
                } else {
                    console.error("Failed to refresh token.");
                }
            } else {
                console.error("An error occurred during file deletion:", error);
            }

        }
    };


    return (
        <section>
            <div className="row p-0 m-0">
                <div className="col-md-1">
                    <NavBar />
                </div>
                <div className="col-md-11">
                    <div className="container-fluid ps-3 mt-3">
                        <div className="row">
                            <div>
                                <h1 className="title ms-4 ">Vendor Questionnaire</h1>
                                <p className="p-text fs-6 ps-4">
                                    Please answer all questions to complete the vendor onboarding questionnaire.
                                    Our vendor management team will reach out should there be any additional questions.
                                    Please reach out to “client@client.com” if there are any questions regarding the questionnaire.
                                </p>
                                {questionnaire.length > 1 && (
                                    <div className="border-top border-3 position-relative mt-5 mb-4 w-50 start-50 translate-middle-x d-flex justify-content-between">
                                        {questionnaire.map((e, index) => (
                                            <div key={index}>
                                                <div className="position-absolute bottom-50 translate-middle-x w-100">
                                                    <p className="text-center ps-2 fs-6">
                                                        {e[0]?.pirm_addon__Question__r.pirm_addon__Template__r.Name || 'Unnamed Template'}
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => handleClick(index)}
                                                    className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                                    style={{
                                                        width: '22px',
                                                        height: '22px',
                                                        borderRadius: '50%',
                                                        background: '#fff',
                                                        cursor: 'pointer'
                                                    }}>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                                        style={{
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            background: status[index] ? '#0176D3' : '#fff'
                                                        }}>
                                                        <div
                                                            style={{
                                                                width: '8px',
                                                                height: '8px',
                                                                borderRadius: '50%',
                                                                background: status[index] ? '#fff' : '#C9C9C9'
                                                            }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <Questions
                                    setAllQuestions={setAllQuestions}//state use to update 

                                    questionnaire={questionnaire[position]?.filter(item => item.pirm_addon__IsApplicable__c)}// sepecif array of quetions 
                                    allQuestions={allQuestions}//all questions
                                    position={position}
                                    id={id}
                                    Audits_r={Audits_r}//use to make complete when submit 
                                    pirm_addon__Status__c={pirm_addon__Status__c} // completed or not 
                                    TestClickUpdate={TestClickUpdate}
                                    updateAnswer={updateAnswer}
                                    DeleteFileParent={DeleteFileParent}
                                // clickStatus={clickStatus[position].filter(item => item.pirm_addon__IsApplicable__c)}
                                />
                                <div className="d-flex justify-content-end align-items-center text-center mb-4">
                                    {questionnaire.length > 1 && (
                                        <>
                                            {position === 0 ? (
                                                <button
                                                    className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                    onClick={() => handleClick(position + 1)}>
                                                    Next Section
                                                </button>
                                            ) : position === questionnaire.length - 1 ? (
                                                <button
                                                    className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                    onClick={() => handleClick(position - 1)}>
                                                    Prev Section
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        className={`btn ${css.nextSectionbtn} text-white mt-3 me-2`}
                                                        onClick={() => handleClick(position - 1)}>
                                                        Prev Section
                                                    </button>
                                                    <button
                                                        className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                        onClick={() => handleClick(position + 1)}>
                                                        Next Section
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
