import React, { useEffect, useState } from 'react';
import SkeletonPDF from '../../LoadingPages/SkeletonPDF';
import { isDesktop } from 'react-device-detect';
import css from '../style.module.css';

export default function PDF({ details, name }) {
    const { pdfData } = details;
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (pdfData) {
            try {
                // Convert base64 string to a Blob and create a URL for the PDF
                const byteCharacters = atob(pdfData);
                const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Only update the URL if it has changed to avoid unnecessary re-renders
                const newUrl = URL.createObjectURL(blob);
                setPdfUrl(newUrl);
            } catch (error) {
                console.error('Error processing PDF:', error);
            }
        }
    }, [pdfData]); // Only re-run when pdfData changes

    const openPdf = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };

    if (!pdfData) return <SkeletonPDF />;

    return (
        <div className={isDesktop ? css.size : `bg-white pt-3 rounded-2 ${css.MobileView}`}>
            {isDesktop ? (
                <iframe src={pdfUrl} width="100%" height="100%" title="PDF Viewer" className='rounded-4 px-1 py-2' />
            ) : (
                <>
                    <h4 className='border-bottom border-2 px-2'>Notes & Attachments</h4>
                    <div className='d-flex mb-2 py-2 px-3'>
                        <i className="fa-solid fa-file-pdf text-red my-auto me-2 fa-xl" />
                        <p onClick={openPdf} className='text-primary holdCursor my-auto'>{name}.pdf</p>
                    </div>
                    <p className='text-center py-2 border-top'>View PDF File</p>
                </>
            )}
        </div>
    );
}
