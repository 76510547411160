import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { Security, LoginCallback } from "@okta/okta-react";
import LayOuts from "./components/LayOuts/LayOuts";
import Home from "./components/Home/Home";
import PolicesDetails from "./components/PolicesDetails/PolicesDetails";
import { useEffect } from "react";
import Protected from "./components/Protected/Protected";
import PoliciesProvider from "./Context/ploicesContext";

import { toRelativeUrl } from "@okta/okta-auth-js";
import Login from "./components/Login/Login";
import AppProvider from "./Context/AppContext";
import {
  OktaConfigProvider,
  useOktaConfig,
} from "./Context/CredentialsContext";
import Domain from "./components/Login/Domain";
import Vendors from "./components/Vendors/Vendors";
import Questionnaires from "./components/Vendors/Questionnaires";
import VendorProvider from "./Context/vendorContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SalsefoeceProvider from "./Context/SF.CredantialsContext";
import VendorLogin from "./components/Login/VendorLogin";
import VendorLoginProvider from "./Context/vendorLogin";
import { Decryption } from "./lib/Decryption";

// import Questions from "./components/Vendors/Questions";

const CALLBACK_PATH = "/login/callback";

function App() {
  const navigate = useNavigate();
  const { oktaAuths, setOktaConfig } = useOktaConfig();
  const location = useLocation();

  useEffect(() => {

    const credentials = localStorage.getItem("_Id");
    
    if (credentials) {
      const decryptData = Decryption(credentials);
      setOktaConfig(decryptData);
    }
    // console.log("oktaAuths: ", oktaAuths);
  }, [setOktaConfig]); // Ensure useEffect only depends on setOktaConfig

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };
  if (
    location.pathname === "/vendorlogin" ||
    location.pathname === "/vendors" ||
    location.pathname === "/questionnaires"
  ) {
    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Compliance Portal</title>
        </Helmet>
        <VendorLoginProvider>
          <SalsefoeceProvider>
            <VendorProvider>        
                  <Routes>
                    <Route element={<LayOuts />}>
                      <Route
                        path="/vendors"
                        element={
                          <Protected>
                            <Vendors />
                          </Protected>
                        }
                      />
                      <Route
                        path="/questionnaires"
                        element={
                          <Protected>
                            <Questionnaires />
                          </Protected>
                        }
                      />
                      <Route path="/vendorlogin" element={<VendorLogin />} />
                    </Route>
                  </Routes>

            </VendorProvider>
          </SalsefoeceProvider>
        </VendorLoginProvider>
      </HelmetProvider>
    );
  }

  return !oktaAuths ? (
    <Domain />
  ) : (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Compliance Portal</title>
      </Helmet>
      <Security oktaAuth={oktaAuths} restoreOriginalUri={restoreOriginalUri}>
        <SalsefoeceProvider>
          <PoliciesProvider>
            <AppProvider>
              <Routes>
                <Route element={<LayOuts />}>
                  <Route path="/" element={<Login />} />
                  <Route
                    path="/home"
                    element={
                      <Protected>
                        <Home />
                      </Protected>
                    }
                  />
                  <Route path={CALLBACK_PATH} element={<LoginCallback />} />
                  <Route
                    path="/details/:name/:id"
                    element={
                      <Protected>
                        <PolicesDetails />
                      </Protected>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
              </Routes>
            </AppProvider>
          </PoliciesProvider>
        </SalsefoeceProvider>
      </Security>
    </HelmetProvider>
  );
}

export default function Root() {
  return (
    <OktaConfigProvider>
      <App />
    </OktaConfigProvider>
  );
}
