import React, { useContext, useEffect, useState } from 'react';
import TablePolices from "./TablePolices/TablePolices";
import MainDetails from './MainDetalis/MainDetalis';
import { PoliciesContext } from '../../Context/ploicesContext';
import LoadingMainCard from '../LoadingPages/LoadingMainCard';
import SkeletonTablePolices from '../LoadingPages/SkeletonTablePolices';
import NavBar from '../NavBar/NavBar';
import { Link } from 'react-router-dom';

export default function Home() {
  const { policies } = useContext(PoliciesContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  
 
  useEffect(() => {
    if (policies && policies.data) {
      setData(policies.data);
      setLoading(false);
    }
  }, [policies]);


  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      const results = data.filter(item =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(results);
    } else {
      setFilteredData([]);
    }
  };


  return (
    <section className='overflow-hidden' >
      <div className="row p-0 m-0">
        <div className="col-md-1">
        <NavBar />
        </div>
        <div className="col-md-11">
        <div className="container-fluid mt-3 ms-3">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h1 className="title">Compliance Portal</h1>
          </div>
          <div className="col-md-4 ">
            <div className="search-container position-relative">
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control inputColor py-2"
                  placeholder="Hinted search text "
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <span className="input-group-text">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
              {filteredData.length > 0 && (
                <div className="search-results position-absolute mt-1 ">
                  <ul className="list-group ">
                    {filteredData.map((policy, index) => (
                      <li key={index} className=" list-group-item  ">
                        <Link
                          to={`/details/${policy.Name}/${policy.Id}`}
                          state={{ policy }}
                          className="text-decoration-none text-dark"
                        >
                          {policy.Name} {/* Use `item.Name` to display the name */}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <LoadingMainCard cards={4} />
            <SkeletonTablePolices rows={5} />
          </>
        ) : (
          <>
            <MainDetails data={data} />
            <TablePolices data={data} /> {/* Pass searchTerm as prop */}
          </>
        )}
      </div>
        </div>
      </div>
     
    </section>
  );
}