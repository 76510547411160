import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../NavBar/NavBar';
import { SalesforceContext } from "../../Context/SF.CredantialsContext";
import PDF from './PDF/PDF';
import SkeletonPDF from '../LoadingPages/SkeletonPDF';
import css from './style.module.css';

export default function PoliciesDetails() {
    const { name, id } = useParams();
    const [details, setDetails] = useState(null);
    const { tokens, fetchCredentials } = useContext(SalesforceContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!tokens || !id) return;
            try {
                const { data } = await axios.post(
                    `https://api-skhwrpqgyq-uc.a.run.app//polices/${id}`,
                    {}, // No body needed for POST request
                    {
                        headers: {
                            Authorization: `Bearer ${tokens}`,
                        }
                    }
                );
                setDetails(data);
            } catch (error) {
                if (
                    error.response.data.message === "Token expired" &&
                    error.response.status === 401
                ) {
                    await fetchCredentials();
                } else console.error("Error fetching data:", error);

            }
        };
        fetchData();
    }, [tokens, id]); // Only fetch when tokens or id change

    return (
        <div className="overflow-hidden" >
            <div className="row p-0 m-0">
                <div className="col-md-1">
                    <NavBar />
                </div>
                <div className="col-md-11">
                    <div className="container-fluid pt-3 mb-3">
                        <div className={`bg-white mb-3 rounded-2 d-flex documents  ${css.documentHeader} `} >
                            <h5 className={`m-0 py-2 ms-3  ${css.headerText}`} >{name}</h5>
                        </div>

                        <div className="vh-100">

                            {details ? <PDF name={name} details={details} /> : <SkeletonPDF />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

