import { createContext, useState } from "react";
import { Navigate } from "react-router-dom";

export const AppContext = createContext([]);

export default function AppProvider({ children }) {
  const [app, setApp] = useState([]);
  const tokenStorage = JSON.parse(localStorage.getItem("okta-token-storage"));
  const expiresAt = tokenStorage?.accessToken?.expiresAt;
  const session = () => {
    const date = new Date(expiresAt * 1000);
    const createdAt = Math.floor(Date.now() / 1000); 
    const timeoutDuration = (date.getTime() / 1000 - createdAt) * 1000; 

    const sessionId =
      Math.random().toString(36).slice(2) +
      Math.random().toString(36).toUpperCase().slice(2);

    localStorage.setItem("sessionId", sessionId);
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("okta-token-storage");
      localStorage.removeItem("okta-cache-storage");
      localStorage.removeItem("sessionId");
      <Navigate to="/" />;
    }, timeoutDuration);
    return () => clearTimeout(timeoutId);
  };
  return (
    <AppContext.Provider value={{ app, setApp, session }}>
      {children}
    </AppContext.Provider>
  );
}
